@font-face {
    font-family: "OpenSans";
    src: url("./NotoSans-Regular.ttf");
	font-weight: 400;
}
@font-face {
    font-family: "OpenSans";
    src: url("./NotoSans-Regular.ttf");
	font-weight: normal;
}
@font-face {
    font-family: "OpenSans";
    src: url("./NotoSans-SemiBold.ttf");
	font-weight: 600;
}
@font-face {
    font-family: "OpenSans";
    src: url("./NotoSans-Bold.ttf");
	font-weight: bold;
}

@font-face {
    font-family: "OpenSans-SemiBold";
    src: url("./NotoSans-SemiBold.ttf")
}

.openSansFont{
    font-family: "OpenSans";
}
.openSansSemiBoldFont{
    font-family: "OpenSans-SemiBold";
}