@font-face {
  font-family: "iconfont"; /* Project id 1165195 */
  src: url('iconfont.woff2?t=1730253465613') format('woff2'),
       url('iconfont.woff?t=1730253465613') format('woff'),
       url('iconfont.ttf?t=1730253465613') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: initial;
}

.icon-rili:before {
  content: "\e66d";
}

.icon-takeout_dining:before {
  content: "\e66c";
}

.icon-Checkbox_not_disabled:before {
  content: "\e66b";
}

.icon-Checkbox_not_hover:before {
  content: "\e66a";
}

.icon-Checkbox_not_pressed:before {
  content: "\e669";
}

.icon-Checkbox_not_default:before {
  content: "\e668";
}

.icon-Radio_pressed1:before {
  content: "\e666";
}

.icon-Radio_hover1:before {
  content: "\e664";
}

.icon-Radio_pressed:before {
  content: "\e665";
}

.icon-Radio_hover:before {
  content: "\e663";
}

.icon-checkbox_pressed:before {
  content: "\e662";
}

.icon-a-OrderMeals:before {
  content: "\e660";
}

.icon-a-OrderMeals1:before {
  content: "\e661";
}

.icon-Clean:before {
  content: "\e65f";
}

.icon-memberPoints:before {
  content: "\e65e";
}

.icon-a-Language3:before {
  content: "\e65d";
}

.icon-More:before {
  content: "\e65c";
}

.icon-editInfo:before {
  content: "\e65b";
}

.icon-addInfo:before {
  content: "\e65a";
}

.icon-delete1:before {
  content: "\e657";
}

.icon-deleteAccount:before {
  content: "\e658";
}

.icon-resetPassword:before {
  content: "\e659";
}

.icon-Account:before {
  content: "\e656";
}

.icon-orderHistory:before {
  content: "\e654";
}

.icon-addressInfo:before {
  content: "\e652";
}

.icon-paymentInfo:before {
  content: "\e653";
}

.icon-logout1:before {
  content: "\e651";
}

.icon-accountInfo:before {
  content: "\e650";
}

.icon-back2:before {
  content: "\e64e";
}

.icon-invisible:before {
  content: "\e64d";
}

.icon-password-eye:before {
  content: "\e64b";
}

.icon-password_success:before {
  content: "\e64c";
}

.icon-password_error:before {
  content: "\e64a";
}

.icon-jianshao:before {
  content: "\e68e";
}

.icon-jianshao1:before {
  content: "\e648";
}

.icon-function_minus:before {
  content: "\e649";
}

.icon-jian:before {
  content: "\e647";
}

.icon-moreclass:before {
  content: "\e62c";
}

.icon-info1:before {
  content: "\e612";
}

.icon-success:before {
  content: "\e67e";
}

.icon-hujiaozhongxin:before {
  content: "\e602";
}

.icon-lbs:before {
  content: "\e646";
}

.icon-a-Offtime:before {
  content: "\e613";
}

.icon-a-Frame1267:before {
  content: "\e614";
}

.icon-a-Frame1228:before {
  content: "\e615";
}

.icon-a-Frame1268:before {
  content: "\e616";
}

.icon-check_box_outline_blank:before {
  content: "\e611";
}

.icon-radio_button_checked:before {
  content: "\e60d";
}

.icon-radio_button_unchecked:before {
  content: "\e60e";
}

.icon-check_box:before {
  content: "\e60f";
}

.icon-arrow_right:before {
  content: "\e645";
}

.icon-autorenew:before {
  content: "\e644";
}

.icon-a-Frame10691:before {
  content: "\e643";
}

.icon-Frame1:before {
  content: "\e642";
}

.icon-a-Frame1069:before {
  content: "\e641";
}

.icon-Back:before {
  content: "\e640";
}

.icon-a-Frame1067:before {
  content: "\e63e";
}

.icon-Frame:before {
  content: "\e63b";
}

.icon-qr_code:before {
  content: "\e639";
}

.icon-history:before {
  content: "\e638";
}

.icon-Subtract:before {
  content: "\e637";
}

.icon-pointsway1:before {
  content: "\e636";
}

.icon-a-table21:before {
  content: "\e62b";
}

.icon-a-bag1:before {
  content: "\e62a";
}

.icon-tupian:before {
  content: "\ecb3";
}

.icon-Vector1:before {
  content: "\e629";
}

.icon-Vector:before {
  content: "\e628";
}

.icon-Variant4:before {
  content: "\e627";
}

.icon-a-check3:before {
  content: "\e625";
}

.icon-pos-terminal:before {
  content: "\e624";
}

.icon-wechat:before {
  content: "\e623";
}

.icon-money:before {
  content: "\e622";
}

.icon-icon_zhifuunionpay:before {
  content: "\e64f";
}

.icon-a-Google_Pay-Logowine1:before {
  content: "\e620";
}

.icon-arrow_back:before {
  content: "\e61e";
}

.icon-credit-card:before {
  content: "\e61d";
}

.icon-apple-pay:before {
  content: "\e61c";
}

.icon-shop:before {
  content: "\e61b";
}

.icon-gift-box:before {
  content: "\e61a";
}

.icon-weibiao45133-new:before {
  content: "\ecb4";
}

.icon-weibiao45133:before {
  content: "\e63c";
}

.icon-gou:before {
  content: "\e881";
}

.icon-list:before {
  content: "\e626";
}

.icon-CreditCard:before {
  content: "\e655";
}

.icon-license:before {
  content: "\e68a";
}

.icon-delete:before {
  content: "\e607";
}

.icon-edit:before {
  content: "\e609";
}

.icon-card:before {
  content: "\e618";
}

.icon-bag1:before {
  content: "\e63d";
}

.icon-language:before {
  content: "\e693";
}

.icon-new1:before {
  content: "\e667";
}

.icon-new2:before {
  content: "\e610";
}

.icon-new:before {
  content: "\e619";
}

.icon-liwu:before {
  content: "\e6d8";
}

.icon-tuijian:before {
  content: "\e63f";
}

.icon-down:before {
  content: "\e70c";
}

.icon-login-user:before {
  content: "\e89f";
}

.icon-time1:before {
  content: "\e89d";
}

.icon-dollar:before {
  content: "\e89e";
}

.icon-star:before {
  content: "\e89c";
}

.icon-pepper:before {
  content: "\e89a";
}

.icon-guest1:before {
  content: "\e899";
}

.icon-table:before {
  content: "\e898";
}

.icon-kitchen:before {
  content: "\e897";
}

.icon-search:before {
  content: "\e894";
}

.icon-item:before {
  content: "\e893";
}

.icon-back:before {
  content: "\e88f";
}

.icon-home:before {
  content: "\e88e";
}

.icon-info:before {
  content: "\e88c";
}

.icon-question:before {
  content: "\e861";
}

.icon-prompt:before {
  content: "\e862";
}

.icon-sale:before {
  content: "\e863";
}

.icon-empty:before {
  content: "\e85f";
}

.icon-arrow-down:before {
  content: "\e85c";
}

.icon-arrow-up:before {
  content: "\e85d";
}

.icon-add:before {
  content: "\e85b";
}

.icon-vip:before {
  content: "\e85a";
}

.icon-orderhistory:before {
  content: "\e859";
}

.icon-accountsetting:before {
  content: "\e858";
}

.icon-logout:before {
  content: "\e857";
}

.icon-time:before {
  content: "\e856";
}

.icon-rest:before {
  content: "\e855";
}

.icon-location:before {
  content: "\e854";
}

.icon-arrow:before {
  content: "\e852";
}

.icon-circula:before {
  content: "\e851";
}

.icon-user:before {
  content: "\e84f";
}

.icon-bag:before {
  content: "\e84e";
}

.icon-diamond:before {
  content: "\e84d";
}

.icon-close:before {
  content: "\e84c";
}

.icon-minus:before {
  content: "\e849";
}

.icon-plus:before {
  content: "\e84a";
}

